import { NavLink, Outlet } from '@remix-run/react'
import {
  Bell,
  Calendar,
  ChevronDown,
  Home,
  LineChart,
  Menu,
  PanelRightOpen,
  PawPrint,
  Search,
  Settings,
  Users,
  X,
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar'
import { Button } from '~/components/ui/button'
import { Input } from '~/components/ui/input'
import { cn } from '~/lib/utils'

const navItems = [
  { to: '/tablero', icon: Home, label: 'Dashboard' },
  { to: '/citas', icon: Calendar, label: 'Citas' },
  { to: '/pacientes', icon: Users, label: 'Pacientes' },
  { to: '/estadisticas', icon: LineChart, label: 'Estadísticas' },
  { to: '/configuracion', icon: Settings, label: 'Configuración' },
]

export default function VetDashboard() {
  const { sidebarOpen, setSidebarOpen, sidebarCollapsed, setSidebarCollapsed } =
    useSidebar()

  if (sidebarCollapsed === null) {
    return null // or a loading spinner
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <MobileOverlay
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <MainContent sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </div>
  )
}

interface SidebarProps {
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
  sidebarCollapsed: boolean
  setSidebarCollapsed: (collapsed: boolean) => void
}

function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  sidebarCollapsed,
  setSidebarCollapsed,
}: SidebarProps) {
  return (
    <aside
      className={cn(
        'bg-primary min-h-screen flex flex-col transition-all duration-300 ease-in-out',
        sidebarOpen ? 'translate-x-0' : '-translate-x-full',
        'md:translate-x-0 md:static fixed z-50',
        sidebarCollapsed ? 'w-20' : 'w-72',
        'rounded-r-[40px] shadow-xl'
      )}
    >
      <SidebarHeader sidebarCollapsed={sidebarCollapsed} />
      <SidebarNav sidebarCollapsed={sidebarCollapsed} />
      <SidebarFooter
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <MobileCloseButton setSidebarOpen={setSidebarOpen} />
    </aside>
  )
}

interface SidebarHeaderProps {
  sidebarCollapsed: boolean
}

function SidebarHeader({ sidebarCollapsed }: SidebarHeaderProps) {
  return (
    <div className="flex items-center justify-start p-3 h-24">
      <div className="bg-white p-3 rounded-full shadow-md">
        <PawPrint className="text-primary w-8 h-8 flex-shrink-0" />
      </div>
      <h2
        className={cn(
          'text-2xl font-bold text-white ml-3 transition-all duration-300',
          sidebarCollapsed ? 'opacity-0 w-0' : 'opacity-100 w-auto'
        )}
      >
        VetApp
      </h2>
    </div>
  )
}

interface SidebarNavProps {
  sidebarCollapsed: boolean
}

function SidebarNav({ sidebarCollapsed }: SidebarNavProps) {
  return (
    <nav className="flex-1 px-4 py-6">
      <ul className="space-y-4">
        {navItems.map(({ to, icon: Icon, label }) => (
          <li key={to}>
            <NavLink
              to={to}
              className={({ isActive }) =>
                cn(
                  isActive
                    ? 'bg-white text-primary'
                    : 'text-white hover:bg-white/10',
                  'flex items-center px-3 py-3 rounded-xl transition-all duration-200',
                  sidebarCollapsed && 'justify-start'
                )
              }
            >
              <Icon className="w-6 h-6 flex-shrink-0" />
              <span
                className={cn(
                  'ml-3 transition-opacity duration-300',
                  sidebarCollapsed ? 'opacity-0 w-0' : 'opacity-100 w-auto'
                )}
              >
                {label}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

interface SidebarFooterProps {
  sidebarCollapsed: boolean
  setSidebarCollapsed: (collapsed: boolean) => void
}

function SidebarFooter({
  sidebarCollapsed,
  setSidebarCollapsed,
}: SidebarFooterProps) {
  return (
    <div className="px-4 py-6 hidden md:block">
      <Button
        variant="outline"
        className="w-full rounded-xl justify-start bg-white/10 text-white hover:bg-white/20 hover:text-white border-none px-3"
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
      >
        <PanelRightOpen
          className={cn(
            'w-6 h-6 transition-all duration-300',
            sidebarCollapsed ? 'rotate-180' : '',
            'flex-shrink-0'
          )}
        />
        {!sidebarCollapsed ? <span className="ml-2">Colapsar</span> : null}
      </Button>
    </div>
  )
}

function MobileCloseButton({
  setSidebarOpen,
}: {
  setSidebarOpen: (open: boolean) => void
}) {
  return (
    <div className="md:hidden absolute top-4 right-4">
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setSidebarOpen(false)}
        className="text-white hover:bg-primary-foreground"
      >
        <X className="h-6 w-6" />
      </Button>
    </div>
  )
}

function MobileOverlay({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}) {
  if (!sidebarOpen) return null

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
      onClick={() => setSidebarOpen(false)}
      onKeyDown={(e) => e.key === 'Escape' && setSidebarOpen(false)}
      role="button"
      tabIndex={0}
    />
  )
}

function MainContent({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}) {
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
        <Outlet />
      </main>
    </div>
  )
}

function Header({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}) {
  return (
    <header className="bg-white shadow-sm">
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center space-x-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="md:hidden"
          >
            <Menu />
          </Button>
          <SearchBar className="hidden md:block" />
        </div>
        <div className="flex items-center space-x-4">
          <Button variant="ghost" size="icon">
            <Bell />
          </Button>
          <Avatar>
            <AvatarImage alt="@veterinario" />
            <AvatarFallback>VT</AvatarFallback>
          </Avatar>
          <div className="hidden md:flex items-center">
            <span className="text-sm font-medium">Dr. García</span>
            <ChevronDown className="w-4 h-4 ml-1" />
          </div>
        </div>
      </div>
      <SearchBar className="p-4 pt-0 md:hidden" />
    </header>
  )
}

function SearchBar({ className }: { className?: string }) {
  return (
    <div className={className}>
      <div className="relative">
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          type="search"
          placeholder="Buscar..."
          className="pl-8 w-full md:w-[300px]"
        />
      </div>
    </div>
  )
}

export function useSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean | null>(null)

  useEffect(() => {
    const saved = localStorage.getItem('sidebarCollapsed')
    setSidebarCollapsed(saved !== null ? JSON.parse(saved) : false)
  }, [])

  useEffect(() => {
    if (sidebarCollapsed !== null) {
      localStorage.setItem('sidebarCollapsed', JSON.stringify(sidebarCollapsed))
    }
  }, [sidebarCollapsed])

  return {
    sidebarOpen,
    setSidebarOpen,
    sidebarCollapsed,
    setSidebarCollapsed,
  }
}
